
import { defineComponent, reactive, ref, toRef, onMounted } from "vue";
import { hideModal, showModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import ApiService from "@/core/services/ApiService";
import { object, string, InferType } from "yup";

export default defineComponent({
  name: "edit-location-modal",
  components: {},
  props: ["account", "location"],
  setup(props, context) {
    const schema = object({
      id: string(),
      name: string().required(),
      code: string().required(),
      address: object({
        street1: string().required("The street address is a required field."),
        street2: string(),
        city: string().required(),
        state: string().required(),
        zip: string().required(),
        country: string().required(),
      }),
    });
    type FormModel = InferType<typeof schema>;
    const formModel = schema.cast(props.location) as FormModel;

    const errors = reactive({
      global: "",
      name: "",
      code: "",
      "address.street1": "",
      "address.street2": "",
      "address.city": "",
      "address.state": "",
      "address.zip": "",
      "address.country": "",
    });

    const editLocationRef = ref<null | HTMLElement>(null);
    const loading = ref<boolean>(false);

    let targetData = toRef(props, "location");

    const deleteLocation = async () => {
      if (formModel.id !== "") {
        try {
          loading.value = true;
          let response = await ApiService.delete(
            "api/v1/locations/" + formModel.id
          );
          hideModal(editLocationRef.value);
          context.emit("locationUpdated");
        } catch (e) {
          Swal.fire({
            text: "Error deleting location!",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Ok",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
        } finally {
          loading.value = false;
        }
      }
    };

    const validateField = (field) => {
      schema
        .validateAt(field, formModel)
        .then(() => {
          errors[field] = "";
        })
        .catch((err) => {
          errors[field] = err.errors[0];
        });
    };

    const submit = async () => {
      try {
        loading.value = true;
        await schema.validate(formModel, { abortEarly: false });
      } catch (e) {
        e.inner.forEach((error) => {
          errors[error.path] = error.message;
        });
        loading.value = false;
        console.log(e.inner);
        return;
      }

      try {
        if (formModel.id === "" || formModel.id === undefined) {
          let postData = Object.assign({}, formModel);
          delete postData.id;
          await ApiService.post("api/v1/locations", {
            ...postData,
          });
        } else {
          await ApiService.update("api/v1/locations", props.location.id, {
            ...formModel,
          });
        }
        hideModal(editLocationRef.value);
        context.emit("locationUpdated");
      } catch (e) {
        for (let i = 0; i < e.response.data.violations.length; i++) {
          let violation = e.response.data.violations[i];
          if (!(violation.propertyPath in errors)) {
            errors["global"] = violation.message;
          } else {
            errors[violation.propertyPath] = violation.message;
          }
        }
      } finally {
        loading.value = false;
      }
    };

    onMounted(() => {
      showModal(editLocationRef.value);
    });

    return {
      formModel,
      submit,
      loading,
      editLocationRef,
      deleteLocation,
      errors,
      schema,
      validateField,
    };
  },
});
